import React from 'react'
import { connect } from 'react-redux'

import Grid from '@material-ui/core/Grid'
import Select from 'react-select'
import Button from '@material-ui/core/Button'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogTitle from '@material-ui/core/DialogTitle'
import IconButton from '@material-ui/core/IconButton'
import CloseIcon from '@material-ui/icons/Close'
import { FormControl, FormGroup, FormControlLabel, Checkbox } from '@material-ui/core'
import { getLeaguesByFacility, getTournamentsByFacility } from 'league/actions'
import { updateTeamLeague } from 'teams/actions'
import { openConfirmModal } from 'common/actions'
import { actions } from 'teams/redux'

class ChangeLeagueDialog extends React.Component {
  render() {
    let divisionOptions = this.props.selectedLeagueOrTournament?.divisions || this.props.selectedLeagueOrTournament?.leagues
    divisionOptions = divisionOptions?.map(division =>({
      label: `${division.name}`,
      value: division.ID,
      ID: division.ID,
      name: division.name,
      obj: {...division},
    }))
    const { open, handleClose } = this.props
    return (
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="form-dialog-title"
        maxWidth="md"
        fullWidth
      >
        <DialogTitle id="form-dialog-title">
          Change league
          <IconButton
            style={{
              cursor: 'pointer',
              float: 'right',
            }}
            onClick={handleClose}
          >
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent>
          <Grid
            container
            direction="row"
            justify="flex-start"
            alignItems="flex-start"
          >
            <Grid item xs={12}>
              <Select
                options={this.props.facilities}
                placeholder="Select facility..."
                styles={{ menuPortal: base => ({ ...base, zIndex: 9999 }), container: (provided) => ({ ...provided, marginBottom: '10px' }) }}
                menuPortalTarget={document.body}
                value={
                  this.props.facility_id &&
                  this.props.facilities.find(
                    f => f.value === this.props.filter.facility_id
                  )
                }
                onChange={ async e => {
                  await this.props.changeFilterDetails('facility_id', e.value)
                  await this.props.changeFilterDetails('product_type_id', this.props.current_league?.new_product?.gl?.product_type_id)

                  if (this.props.filter.fetch_leagues) {
                    this.props.getLeaguesByFacility(e.value, false)
                  } else {
                    this.props.getTournamentsByFacility(e.value)
                  }
                }}
                variant="outlined"
                isDisabled={this.props.disabled_facilities}
                fullWidth
              />
              <Select
                options={[{label: "Leagues", value: true}, {label: "Tournaments", value: false}]}
                placeholder={this.props.leagues ? "Fetching Leagues" : "Fetching Tournaments"}
                styles={{ menuPortal: base => ({ ...base, zIndex: 9999 }), container: (provided) => ({ ...provided, marginBottom: '10px' }) }}
                menuPortalTarget={document.body}
                onChange={e => {
                  this.props.changeFilterDetails('fetch_leagues', e.value);
                if (e.value) {
                  this.props.getLeaguesByFacility(this.props.filter.facility_id, false)
                } else {
                  this.props.getTournamentsByFacility(this.props.filter.facility_id)
                }}}
                variant="outlined"
                fullWidth
                />
              <Select
                options={
                  this.props.filter.fetch_leagues
                    ?  this.props.leagues: 
                    this.props.tournaments
                }
                placeholder={
                  this.props.filter.fetch_leagues
                    ? 'Select League':'Select Tournament'
                }
                styles={{ menuPortal: base => ({ ...base, zIndex: 9999 }), container: (provided) => ({ ...provided, marginBottom: '10px' }) }}
                menuPortalTarget={document.body}
                value={
                  this.props.is_tournament
                    ? this.props.tournaments?.find(
                        f => f.value === this.props.filter.season_id
                      )
                    : this.props.leagues?.find(
                        f => f.value === this.props.filter.season_id
                      )
                }
                onChange={e => {
                  this.props.changeFilterDetails('season_id', e.value)
                  this.props.selectLeagueOrTournament(e.obj)
                }}
                variant="outlined"
                fullWidth
              />

              <Select
                options={
                  divisionOptions
                }
                placeholder="Select Division"
                styles={{ menuPortal: base => ({ ...base, zIndex: 9999 }), container: (provided) => ({ ...provided, marginBottom: '10px' }) }}
                menuPortalTarget={document.body}
                value={
                  divisionOptions?.find(
                        f => f.value === this.props.league?.ID
                  )
                }
                onChange={e => {
                  this.props.changeTeamDetails('new_league', e.obj)
                }}
                variant="outlined"
                fullWidth
              />
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => {
              this.props.updateTeamLeague(`${this.props.selectedLeagueOrTournament.name}: ${this.props.league.name}`)
            }}
            color="primary"
          >
            Save
          </Button>
        </DialogActions>
      </Dialog>
    )
  }
}

const mapStateToProps = (state, ownProps) => {
  const current_ptype_id =
    state.teams.team &&
    state.teams.team.current_league &&
    state.teams.team.current_league.product.gl.product_type_id
  return {
    filter: state.teams.filter,
    open: state.teams.league_dialog_open,
    tournaments: state.league.tournaments.map(league => ({
      label: `${league.name}`,
      value: league.ID,
      ID: league.ID,
      name: league.name,
      obj: {...league},
    })),
    leagues: state.league.leagues.map(league => ({
      label: `${league.name}`,
      value: league.ID,
      ID: league.ID,
      name: league.name,
      obj: {...league},
    })),
    league: state.teams.team_form.new_league,
    is_tournament: state.teams.team?.current_league?.is_tournament,
    current_league:state.teams.team?.current_league,
    facilities: state.common.filter_facilities,
    selectedLeagueOrTournament: state.teams.selectedLeagueOrTournament,
  }
}

const mapDispatchToProps = (dispatch, ownProps) => ({
  handleClose: () => dispatch(actions.toggleChangeLeagueModal({ open: false })),
  getLeaguesByFacility: (id, is_tournament) =>
    dispatch(getLeaguesByFacility(id, is_tournament)),
  getTournamentsByFacility: facilityID =>
    dispatch(getTournamentsByFacility(facilityID)),
  changeFilterDetails: (key, value) =>
    dispatch(actions.changeFilterDetails({ key, value })),
  selectLeagueOrTournament: (obj) =>
    dispatch(actions.selectLeagueOrTournament(obj)),
  changeTeamDetails: (k, v) =>
    dispatch(actions.editTeamDetails({ key: k, value: v })),
  updateTeamLeague: name => {
    dispatch(
      openConfirmModal(
        'League change',
        `change the team's league to ${name}`,
        () => updateTeamLeague()
      )
    )
  },
})

export default connect(mapStateToProps, mapDispatchToProps)(ChangeLeagueDialog)
