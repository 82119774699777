import moment from 'moment'
import * as FileSaver from 'file-saver'
import { ERP_BACKEND_URL } from 'consts'
import Axios from 'axios'
import _ from 'lodash'

const EXCEL_FILETYPE =
    'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8',
  EXCEL_EXTENSION = '.xlsx',
  TIME_FORMAT = 'HH:mm',
  TIME_12_HOUR_FORMAT = 'hh:mma',
  DATE_FORMAT = 'M/D/YYYY',
  DATE_TIME_FORMAT = 'M/D/YYYY HH:mm',
  DATE_TIME_S_FORMAT = 'M/D/YYYY HH:mm:ss'

export const unixToDateTime = unix =>
  moment(moment.utc(unix, 'X').format(DATE_TIME_FORMAT), DATE_TIME_FORMAT)

export const unixToDate = unix =>
  moment(moment.utc(unix, 'X').format(DATE_FORMAT), DATE_FORMAT)

export const unixToTime = unix =>
  moment(moment.utc(unix, 'X').format(TIME_FORMAT), TIME_FORMAT)

export const unixToTime12 = unix =>
  moment.unix(unix).format(TIME_12_HOUR_FORMAT)

export const momentTimeToUnix = moment_time =>
  moment.utc(moment_time.format(TIME_FORMAT), TIME_FORMAT).unix()

export const momentDateToUnix = moment_date =>
  moment.utc(moment_date.format(DATE_FORMAT), DATE_FORMAT).unix()

export const momentDateTimeToUnix = moment_date =>
  moment.utc(moment_date.format(DATE_TIME_FORMAT), DATE_TIME_FORMAT).unix()

export const momentDateTimeSecondsToUnix = moment_date =>
  moment.utc(moment_date.format(DATE_TIME_S_FORMAT), DATE_TIME_S_FORMAT).unix()

export const momentDateTime = date => moment(date).format(DATE_FORMAT)

export const jsDateToUnix = date => momentDateToUnix(moment(date))

export const unixToJsDate = date =>
  date ? new Date(unixToDate(date).toString()) : null

export const applyFilter = (doc, search_query, field) => {
  if (!search_query) return true
  const returnObjectValues = k => doc[k]
  const search = search_query.toLowerCase().split(' ')
  let data = JSON.stringify(
      field
        ? field.map(returnObjectValues)
        : Object.keys(doc).map(returnObjectValues)
    ).toLowerCase(),
    counter = 0
  if (search.length === 0) {
    return true
  }
  search.forEach(word => {
    if (word.indexOf('+') !== -1) {
      const spl = word.split('+')
      spl.forEach(subword => {
        if (data.indexOf(subword) !== -1) {
          counter += 1
        }
      })
    }
    if (data.indexOf(word) !== -1) {
      counter += 1
    }
  })
  return counter >= search.length
}

export const shuffleArray = a => {
  for (let i = a.length - 1; i > 0; i--) {
    const j = Math.floor(Math.random() * (i + 1))
    ;[a[i], a[j]] = [a[j], a[i]]
  }
  return a
}

export const arrayRotate = (arr, times) => {
  for (let i = 0; i < times; i++) {
    arr.push(arr.shift())
  }
  return arr
}

export const openInNewTab = url => {
  const win = window.open(url, '_blank')
  if (win != null) {
    win.focus()
  }
}

export const capitalizeFirst = s => s.charAt(0).toUpperCase() + s.slice(1)

export const parseInvoice = invoice => {
  if (!invoice)
    return {
      invoice_items: [],
      payments: [],
      discounts: [],
      invoiced: 0,
      discounted: 0,
      paid: 0,
      balance: 0,
    }
  return invoice
}

export const niceNumber = x => {
  if (!x && x !== 0) return 'N/A'
  return x
    .toFixed(2)
    .toString()
    .replace(/\B(?=(\d{3})+(?!\d))/g, ',')
}

export const exportToCSV = (row_data, fileName) => {
  import('xlsx').then(XLSX => {
    const ws = XLSX.utils.json_to_sheet(row_data),
      wb = XLSX.utils.book_new()
    XLSX.utils.book_append_sheet(wb, ws, 'Hydra')
    const excelBuffer = XLSX.write(wb, { bookType: 'xlsx', type: 'array' })
    const data = new Blob([excelBuffer], { type: EXCEL_FILETYPE }),
      _fileName = `${fileName}_${moment().format(
        'MM/DD/YYYY-HH:mm:ss'
      )}${EXCEL_EXTENSION}`
    FileSaver.saveAs(data, _fileName)
  })
}

export const downloadURI = uri => {
  var link = document.createElement('a')
  link.setAttribute('download', 'name')
  link.href = uri
  document.body.appendChild(link)
  link.click()
  link.remove()
}

const _htmlDecode = input => {
  var e = document.createElement('textarea')
  e.innerHTML = input
  // handle case of empty input
  return e.childNodes.length === 0 ? '' : e.childNodes[0].nodeValue
}

export const htmlDecode = input => {
  return _htmlDecode(_htmlDecode(input))
}

export const getHubspot = id =>
  Axios.get(ERP_BACKEND_URL + `/customer/hubspot/${id}`).then(resp =>
    openInNewTab(resp.data)
  )

export const getNextSeasonOptionsYear = (past_season, past_year) => {
  const season_rollover = {'Winter 2':['Spring'], 'Spring':['Spring 2', 'Summer'], 'Spring 2':['Summer'], 'Summer':['Fall'], 'Fall':['Fall 2', 'Winter 1'], 'Fall 2':['Winter 1'], 'Winter 1': ['Winter 2']}
  let season_options = []

  //get current year in correct format '20XX-XX'
  let year = past_year
  if (year?.length === 4 + 1 + 2) {
    try {
      let first = parseInt(year.slice(0, 4))
      let second = parseInt(year.slice(5, 7))
    } catch {
      const currentYear = new Date().getFullYear();
      const nextYear = currentYear + 1;
      year = `${currentYear}-${String(nextYear).slice(-2)}`;
    }
  } else {
    const currentYear = new Date().getFullYear();
    const nextYear = currentYear + 1;
    year = `${currentYear}-${String(nextYear).slice(-2)}`;
  }

  let season = past_season
  if (season == 'Winter') {
    season = 'Winter 1'
  }
  if (!(season in season_rollover)) {
    return []
  }

  let queue = [...season_rollover[season]]
  for (let i = 0; i < 5; i++) {
      let res = {}
      season = queue.shift()
      if (season != "Fall 2" && season != 'Spring 2') {
      queue = queue.concat(season_rollover[season])
      }
      res['season'] = season

      if (season === 'Winter 1') {
        let first = parseInt(year.slice(0, 4))
        let second = parseInt(year.slice(5, 7))
        year = String(first+1) + '-' + String(second+1)
      }
      res['year'] = year
      season_options.push(res)
    }
  return season_options
}