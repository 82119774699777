import React, { useEffect } from 'react'
import { connect } from 'react-redux'

import Button from '@material-ui/core/Button'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogTitle from '@material-ui/core/DialogTitle'
import IconButton from '@material-ui/core/IconButton'
import CloseIcon from '@material-ui/icons/Close'
import Grid from '@material-ui/core/Grid'
import Link from '@material-ui/core/Link'
import OpenInNewIcon from '@material-ui/icons/OpenInNew'

import {
  getAllActiveClassesByFacility,
  changeTrial,
  getClassDetailsTrialEdit,
} from 'classes/actions'
import { openConfirmModal } from 'common/actions'
import { actions } from 'classes/redux'
import { colors } from 'consts'
import Select from 'react-select'
import { unixToDate } from 'helper'
import moment from 'moment'

const ChangeTrialDialog = ({
  open,
  handleClose,
  formData,
  changeformData,
  attendee,
  saveNewTrial,
  classInfo,
  classes,
  getAllActiveClassesByFacility,
}) => {
  useEffect(() => {
    if (classInfo?.facility_id) {
      getAllActiveClassesByFacility(classInfo.facility_id)
    }
  }, classInfo)

  const currentlySelectedClass =
    formData.current_class_id &&
    classes.find(f => f.value === formData.current_class_id)

  let divisionsOptions = currentlySelectedClass?.obj?.divisions?.map(d => ({
    value: d.ID,
    label: d.name,
    obj: d,
  }))

  const currentlySelectedDivision =
    formData.current_division_id &&
    divisionsOptions?.find(f => f.value === formData.current_division_id)

  const eventsOptions = currentlySelectedDivision?.obj?.events
    ?.filter(e => e.start_date > moment().unix())
    .map(e => ({
      value: e.ID,
      label: unixToDate(e.start_date).format('dddd, MMMM Do YYYY'),
    }))

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      aria-labelledby="form-dialog-title"
      maxWidth="md"
      fullWidth
    >
      <DialogTitle id="form-dialog-title">
        Change trial date
        <IconButton
          style={{
            cursor: 'pointer',
            float: 'right',
          }}
          onClick={handleClose}
        >
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent>
        <Grid
          container
          direction="row"
          justify="flex-start"
          alignItems="flex-start"
        >
          <Grid item xs={12}>
            <h4>Current Date: {attendee?.event_date}</h4>
          </Grid>
          <Grid item xs={12}>
            <Select
              options={classes}
              placeholder="Select Class..."
              styles={{ menuPortal: base => ({ ...base, zIndex: 9999 }) }}
              menuPortalTarget={document.body}
              value={currentlySelectedClass}
              onChange={async e => {
                await changeformData('current_class_id', e.value)
              }}
              variant="outlined"
              fullWidth
            />
          </Grid>
          <Grid item xs={12}>
            <Select
              options={divisionsOptions}
              placeholder="Select Division..."
              styles={{ menuPortal: base => ({ ...base, zIndex: 9999 }) }}
              menuPortalTarget={document.body}
              value={currentlySelectedDivision}
              onChange={async e => {
                await changeformData('current_division_id', e.value)
              }}
              variant="outlined"
              fullWidth
            />
          </Grid>
          <Grid item xs={12}>
            <Select
              options={eventsOptions}
              placeholder="Select new date..."
              styles={{ menuPortal: base => ({ ...base, zIndex: 9999 }) }}
              menuPortalTarget={document.body}
              value={
                formData.new_event_id &&
                eventsOptions.find(f => f.value === formData.new_event_id)
              }
              onChange={async e => {
                await changeformData('new_event_id', e.value)
              }}
              variant="outlined"
              fullWidth
            />
          </Grid>
          {formData.new_class_id && (
            <Grid item xs={12} style={{ marginTop: 10 }}>
              <h3>Check class</h3>
              <Link href={`/classes/${formData.new_class_id}`} target="_blank">
                <IconButton>
                  <OpenInNewIcon
                    fontSize="small"
                    className="save-btn"
                    style={{
                      backgroundColor: 'transparent',
                      color: colors.blue_strong,
                    }}
                  />
                </IconButton>
              </Link>
            </Grid>
          )}
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button onClick={saveNewTrial} color="primary">
          Save
        </Button>
      </DialogActions>
    </Dialog>
  )
}

const mapStateToProps = (state, ownProps) => ({
  ...state.classes.change_trial,
  classes: state.classes.classes
    ?.filter(c => c.is_container && c.accepts_free_trial)
    .map(c => ({
      value: c.ID,
      label: c.name,
      obj: c,
    })),
  formData: state.classes.change_trial,
  facilities: state.common.filter_facilities,
  classInfo: state.classes.classInfo,
})

const mapDispatchToProps = (dispatch, ownProps) => ({
  handleClose: () =>
    dispatch(actions.editChangeTrial({ key: 'open', value: false })),
  changeformData: (key, value) =>
    dispatch(actions.editChangeTrial({ key, value })),
  getAllActiveClassesByFacility: facilityID =>
    dispatch(getAllActiveClassesByFacility(facilityID)),
  saveNewTrial: () => {
    dispatch(
      openConfirmModal(
        'Player trial class date',
        `change the player's trial date`,
        () => changeTrial()
      )
    )
  },
})

export default connect(mapStateToProps, mapDispatchToProps)(ChangeTrialDialog)
