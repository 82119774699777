import React, { Component } from 'react'
import { connect } from 'react-redux'
import { push } from 'connected-react-router'

import ClearCache from 'react-clear-cache'
import { withStyles } from '@material-ui/core/styles'
import Select from 'react-select'
import Link from '@material-ui/core/Link'
import Drawer from '@material-ui/core/Drawer'
import CssBaseline from '@material-ui/core/CssBaseline'
import Divider from '@material-ui/core/Divider'
import Button from '@material-ui/core/Button'
import MuiAlert from '@material-ui/lab/Alert'

import Menu from './Menu'
import DeleteDialog from './DeleteDialog'
import ConfirmationDialog from './ConfirmationDialog'
import Loader from './Loader'
import ErrorDialog from './ErrorDialog'
import InfoDialog from './InfoDialog'

import { getProductTypes } from './../actions'
import { logout } from './../../user/actions'
import { updateFacilityID } from './../actions'

const drawerWidth = 170

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />
}

const styles = theme => ({
  root: {
    display: 'flex',
  },
  hide: {
    display: 'none',
  },
  drawer: {
    width: drawerWidth,
    flexShrink: 0,
    whiteSpace: 'nowrap',
    textAlign: 'center',
  },
  toolbar: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    padding: theme.spacing(0, 1),
  },
  content: {
    flexGrow: 1,
    padding: theme.spacing(3),
  },
})

class AppWrapper extends Component {
  componentDidMount() {
    this.props.getProductTypes()
  }

  render() {
    const { pathname, classes, children } = this.props

    if (pathname === '/login') {
      return <div>{children}</div>
    }
    if (pathname === '/signup') {
      return <div>{children}</div>
    }
    if (pathname === '/forgot') {
      return <div>{children}</div>
    }
    // let logo = 'https://iamback-emails.s3.amazonaws.com/sofive_logo.png'
    let logo = 'https://hydrafrontendstack-hydrabucket50e5b757-151oj7uj1hk86.s3.us-east-1.amazonaws.com/logo_hydra.png'

    return (
      <div className={classes.root}>
        <DeleteDialog />
        <ConfirmationDialog />
        <ErrorDialog />
        <InfoDialog />
        <Loader />
        <CssBaseline />
        {!this.props.is_referee && (
          <Drawer variant="permanent" className={classes.drawer}>
            <Link
              className={classes.toolbar}
              style={{ padding: 15 }}
              href="/calendar"
            >
              <img src={logo} style={{ width: '150px' }} alt="logo" />
            </Link>
            {this.props.is_admin && (
              <div style={{ padding: 10 }}>
                <Select
                  options={this.props.facilities}
                  placeholder="Facility"
                  styles={{ menuPortal: base => ({ ...base, zIndex: 9999 }) }}
                  menuPortalTarget={document.body}
                  value={
                    this.props.facility_id &&
                    this.props.facilities.find(
                      f => f.value === this.props.facility_id
                    )
                  }
                  onChange={e => this.props.updateFacilityID(e.value)}
                />
              </div>
            )}
            <Button
              style={{
                textAlign: 'center',
                paddingBottom: 10,
                cursor: 'pointer',
                textTransform: 'none',
              }}
              size="small"
              onClick={() => this.props.logout()}
            >
              <u>Logout</u>
            </Button>
            <Divider />
            <Menu />
          </Drawer>
        )}
        <div className={classes.content}>
          <ClearCache>
            {({ isLatestVersion, emptyCacheStorage }) => (
              <div>
                {!isLatestVersion && (
                  <Alert severity="warning">
                    Update available, please click
                    <Link
                      target="_blank"
                      style={{
                        color: 'white',
                        marginLeft: 5,
                        cursor: 'pointer',
                      }}
                      onClick={() => {
                        emptyCacheStorage()
                      }}
                    >
                      <u>here</u>
                    </Link>
                  </Alert>
                )}
              </div>
            )}
          </ClearCache>
          <div className={classes.toolbar} />
          <div>{children}</div>
        </div>
      </div>
    )
  }
}

const mapStateToProps = state => ({
  user: state.user.user,
  pathname: state.router.location.pathname,
  facilities: state.common.filter_facilities,
  facility_id: state.user.facility_id,
  is_admin: state.user.user_type === 'admin',
  is_referee: state.user.user_type === 'referee',
})

const mapDispatchToProps = dispatch => ({
  gotoLogin: () => dispatch(push('/login')),
  getProductTypes: () => dispatch(getProductTypes()),
  updateFacilityID: id => dispatch(updateFacilityID(id)),
  logout: () => dispatch(logout()),
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(styles)(AppWrapper))
