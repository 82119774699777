import { createSlice } from '@reduxjs/toolkit'
import moment from 'moment'
import {
  unixToDateTime,
  momentDateTimeSecondsToUnix,
  parseInvoice,
} from './../helper'
import { GLOBAL_DATE_FORMAT } from './../consts'

const initialState = {
  invoices: [],
  invoice: null,
  search: {
    start_date: momentDateTimeSecondsToUnix(moment().subtract(7, 'd')),
    end_date: momentDateTimeSecondsToUnix(moment().add(1, 'd')),
    facility_id: null,
    product_type_id: null,
    payment_type: null,
    square_id: null,
    customer: null,
  },
  event_modal_open: false,
  selected_invoice_id: null,
  payment_modal_open: false,
  payment_form: {
    method: null,
    amount: 0,
    check_number: null,
    transaction_number: null,
    customer: null,
    card_number: null,
    card_month: null,
    card_year: null,
    card_check: null,
    player_id: null,
  },
  invoice_form: {
    product_types: [],
    customer: null,
    date: null,
    facility_id: null,
    comment: '',
    amount: 0,
    ptype_id: null,
  },
  invoice_modal_open: false,
  fees: [],
  fee_id: null,
  price: null,
  discounts: [],
  discount_modal_open: false,
  discount_id: null,
  add_credit_modal_open: false,
  add_credit_amount: 0,
  custom_discount_amount: 0,
  delete_payment_modal_open: false,
  delete_payment: {
    id: null,
    p_type: null,
    amount: null,
  },
  update_fee: {
    open: false,
    invoice_item_id: null,
    price: null,
    manual_fee: 0,
  },
  terminals: {
    available_terminals: [],
    terminal_id:null,
    terminal_payment_id: null,
    terminal_payment_state: null,
    idempotency_key: crypto.randomUUID()
  }
}

const invoiceSlice = createSlice({
    name: 'invoice',
    initialState,
    reducers: {
      getAllInvoices: (state, { payload }) => {
        state.invoices = payload?.invoices?.map(parseInvoice).map(invoice => {
          const parsed = unixToDateTime(invoice.date)
          return {
            ...invoice,
            pretty_date: parsed.format(GLOBAL_DATE_FORMAT),
          }
        })
      },
      getInvoice: (state, { payload }) => {
        if (!payload.invoice) return
        const invoice = payload.invoice,
          parsed = unixToDateTime(invoice.date),
          is_league = invoice.team_id > 0,
          tax_rate = invoice.facility.tax_rate,
          total_paid_without_tax =
            Math.round(
              (100 * parseFloat(invoice.paid)) / parseFloat(1 + tax_rate)
            ) / 100
        let payments = invoice.payments
        if (is_league) {
          payments = payments.map(p => {
            const player_id = p.player_customer_id,
              customer = invoice.team.roster.find(
                r => r.customer.ID === player_id
              )
            let current_cust = p.customer
            if (customer) {
              current_cust = customer.customer
            }
            return { ...p, customer: current_cust }
          })
        }
        const i_item = invoice.invoice_items[0]
        if (i_item) {
          state.fee_id = `${i_item.product_id};${i_item.price}`
        }
        state.invoice = {
          ...invoice,
          pretty_date: parsed.format(GLOBAL_DATE_FORMAT),
          is_league,
          payments,
          invoice_items: invoice.invoice_items.map(invoice_item => ({
            ...invoice_item,
            fee_id: `${invoice_item.product_id};${invoice_item.price}`,
          })),
          total_without_tax: invoice.invoiced - invoice.taxes,
          total_paid_without_tax,
        }
      },
      updateSearch: (state, { payload }) => {
        state.search = {
          ...state.search,
          [payload.field]: payload.value,
        }
      },
      toggleEventSelectionModal: (state, { payload }) => {
        if (payload.id) {
          state.selected_invoice_id = payload.id
        }
        state.event_modal_open = payload.open
      },
      togglePaymentModal: (state, { payload }) => {
        state.payment_modal_open = payload.open
      },
      updatePayment: (state, { payload }) => {
        state.payment_form = {
          ...state.payment_form,
          [payload.field]: payload.value,
        }
      },
      toggleInvoiceModal: (state, { payload }) => {
        state.invoice_modal_open = payload.open
      },
      updateInvoice: (state, { payload }) => {
        state.invoice_form = {
          ...state.invoice_form,
          [payload.key]: payload.value,
        }
      },
      getFees: (state, { payload }) => {
        state.fees = payload.fees
      },
      getDiscounts: (state, { payload }) => {
        state.discounts = payload.discounts
      },
      toggleDiscountModal: (state, { payload }) => {
        state.discount_modal_open = payload.open
      },
      updateDiscountID: (state, { payload }) => {
        state.discount_id = payload.id
      },
      toggleAddCreditModal: (state, { payload }) => {
        state.add_credit_modal_open = payload.open
        if (payload.amount) {
          state.add_credit_amount = payload.amount
        }
      },
      editDiscountAmount: (state, { payload }) => {
        state.custom_discount_amount = payload.amount
      },
      toggleDeletePaymentModal: (state, { payload }) => {
        state.delete_payment_modal_open = payload.open || false
        if (payload.del) {
          state.delete_payment = { ...state.delete_payment, ...payload.del }
        } else {
          state.delete_payment = initialState.delete_payment
        }
      },
      editPType: (state, { payload }) => {
        state.delete_payment = {
          ...state.delete_payment,
          p_type: payload.p_type,
        }
      },
      updateFee: (state, { payload }) => {
        state.update_fee = {
          ...state.update_fee,
          ...payload,
        }
      },
      updatePrice: (state, { payload }) => {
        state.price = payload.price
      },
      updateAvailableTerminals: (state, {payload}) => {
        state.terminals.available_terminals = payload.value
      },
      updateTerminalPaymentID: (state, {payload}) => {
        state.terminals.terminal_payment_id = payload.id
      },
      updateTerminalPaymentState: (state, {payload}) => {
        state.terminals.terminal_payment_state = payload.state
      },
      updateIdempotencyKey: (state, {payload}) => {
        state.terminals.idempotency_key = payload.idempotency_key
      },
      updateTerminalID: (state, {payload}) => {
        state.terminals.terminal_id = payload.terminal_id
      }
    },
  }),
  actions = invoiceSlice.actions

export { invoiceSlice, actions }
