import React from 'react'
import { connect } from 'react-redux'
import { push } from 'connected-react-router'

import { makeStyles } from '@material-ui/core/styles'
import Link from '@material-ui/core/Link'
import Table from '@material-ui/core/Table'
import TableHead from '@material-ui/core/TableHead'
import TableBody from '@material-ui/core/TableBody'
import TableCell from '@material-ui/core/TableCell'
import TableRow from '@material-ui/core/TableRow'
import Chip from '@material-ui/core/Chip'
import IconButton from '@material-ui/core/IconButton'
import DeleteIcon from '@material-ui/icons/Delete'
import CopyIcon from '@material-ui/icons/FileCopy'
import OpenInNewIcon from '@material-ui/icons/OpenInNew'
import Switch from '@material-ui/core/Switch'
import TablePagination from '@material-ui/core/TablePagination'
import Checkbox from '@material-ui/core/Checkbox'
import { Button } from '@material-ui/core'

import HubspotListDialog from 'common/components/HubspotListDialog'

import { applyFilter } from './../../helper'
import { colors } from './../../consts'
import {
  setLeagueDetails,
  toggleModal,
  deleteLeague,
  getLeagues,
} from './../actions'
import { openDeleteModal } from './../../common/actions'
import { openHubspotList } from 'common/actions'

const useStyles = makeStyles(theme => ({
  maxheight: {
    maxHeight: 650,
    overflow: 'scroll',
  },
  root: {
    padding: 2,
  },
}))

const LeaguesTable = ({
  leagues,
  hide_paid,
  getLeagues,
  duplicateLeague,
  is_admin_or_manager,
  deleteLeague,
  openHubspotList,
}) => {
  const classes = useStyles()
  const [page, setPage] = React.useState(0)
  const [rowsPerPage, setRowsPerPage] = React.useState(20)
  const handleChangePage = (event, newPage) => setPage(newPage)
  const handleChangeRowsPerPage = e => {
    setRowsPerPage(parseInt(e.target.value, 10))
    setPage(0)
  }
  const [checked, setChecked] = React.useState({})
  return (
    <div>
      <HubspotListDialog />
      Displaying <b>{leagues.length}</b> leagues. Hide paid{' '}
      <Switch
        checked={hide_paid}
        onChange={e => getLeagues({ hide_paid: e.target.checked })}
        name="checkedA"
        inputProps={{ 'aria-label': 'secondary checkbox' }}
      />
      <br />
      Totaling{' '}
      <b>
        {leagues.reduce(
          (a, b) =>
            a +
            (b?.is_tournament
              ? b?.leagues?.map(l => l.teams)?.flat().length
              : b?.teams?.length),
          0
        )}
      </b>{' '}
      teams.
      <br />
      <Button
        onClick={() =>
          openHubspotList(Object.keys(checked).filter(k => checked[k]))
        }
      >
        Export hubspot
      </Button>
      <TablePagination
        rowsPerPageOptions={[20, 50, 100]}
        component="div"
        count={leagues.length}
        rowsPerPage={rowsPerPage}
        page={page}
        onChangePage={handleChangePage}
        onChangeRowsPerPage={handleChangeRowsPerPage}
      />
      <Table>
        <TableHead>
          <TableRow>
            <TableCell />
            <TableCell
              style={{ textAlign: 'center', width: '15%' }}
              className="table-header"
            >
              Name
            </TableCell>
            <TableCell
              style={{ textAlign: 'center', width: '15%' }}
              className="table-header"
            >
              Details
            </TableCell>
            <TableCell
              style={{ textAlign: 'center', width: '10%' }}
              className="table-header"
            >
              Facility
            </TableCell>
            <TableCell
              style={{ textAlign: 'center', width: '10%' }}
              className="table-header"
            >
              Start Date
            </TableCell>
            <TableCell
              style={{ textAlign: 'center', width: '10%' }}
              className="table-header"
            >
              End Date
            </TableCell>
            <TableCell
              style={{ textAlign: 'center', width: '10%' }}
              className="table-header"
            >
              Age Group
            </TableCell>
            <TableCell
              style={{ textAlign: 'center', width: '10%' }}
              className="table-header"
            >
              Gender
            </TableCell>
            <TableCell
              style={{ textAlign: 'center', width: '5%' }}
              className="table-header"
            >
              # Teams
            </TableCell>
            <TableCell
              style={{ textAlign: 'center', width: '10%' }}
              className="table-header"
            >
              Full price
            </TableCell>
            <TableCell
              style={{ textAlign: 'center', width: '5%' }}
              className="table-header"
            >
              Action
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {leagues
            .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
            .map(league => (
              <TableRow key={league.ID} style={{ cursor: 'pointer' }}>
                <TableCell style={{ textAlign: 'center' }}>
                  <Checkbox
                    checked={checked[league.ID]}
                    onChange={e => {
                      if (e.target.checked) {
                        setChecked({ ...checked, [league.ID]: true })
                      } else {
                        setChecked({ ...checked, [league.ID]: false })
                      }
                    }}
                    name="hubspot"
                  />
                </TableCell>
                <TableCell
                  style={{ textAlign: 'center', width: '15% !important' }}
                >
                  {league.name}
                </TableCell>
                <TableCell
                  style={{ textAlign: 'center', width: '15% !important' }}
                >
                  <small>
                    Available online:{' '}
                    {league.available_online ? (
                      <span style={{ color: 'green' }}>Yes</span>
                    ) : (
                      <span style={{ color: 'red' }}>No</span>
                    )}
                  </small>
                  <br />
                  <small>
                    Display schedule:{' '}
                    {league.display_schedule ? (
                      <span style={{ color: 'green' }}>Yes</span>
                    ) : (
                      <span style={{ color: 'red' }}>No</span>
                    )}
                  </small>
                  <br />
                  <small>
                    Deposit enabled:{' '}
                    {league.enable_deposit ? (
                      <span style={{ color: 'green' }}>Yes</span>
                    ) : (
                      <span style={{ color: 'red' }}>No</span>
                    )}
                  </small>
                  <br />
                  <small>
                    Individual enabled:{' '}
                    {league.enable_individual ? (
                      <span style={{ color: 'green' }}>Yes</span>
                    ) : (
                      <span style={{ color: 'red' }}>No</span>
                    )}
                  </small>
                </TableCell>
                <TableCell
                  style={{ textAlign: 'center', width: '10% !important' }}
                >
                  {league.facility.name}
                </TableCell>
                <TableCell
                  style={{ textAlign: 'center', width: '10% !important' }}
                >
                  {league.start_date_parsed}
                </TableCell>
                <TableCell
                  style={{ textAlign: 'center', width: '10% !important' }}
                >
                  {league.end_date_parsed}
                </TableCell>
                <TableCell
                  style={{ textAlign: 'center', width: '10% !important' }}
                >
                  <Chip
                    size="small"
                    label={<b>{league.age_group.name}</b>}
                    style={{
                      color: colors.purple_strong,
                      backgroundColor: colors.purple_mild,
                      margin: 5,
                    }}
                  />
                </TableCell>
                <TableCell
                  style={{ textAlign: 'center', width: '10% !important' }}
                >
                  <Chip
                    size="small"
                    label={<b>{league.gender}</b>}
                    style={{
                      color: colors.green_strong,
                      backgroundColor: colors.green_mild,
                      margin: 5,
                    }}
                  />
                </TableCell>
                <TableCell
                  style={{ textAlign: 'center', width: '5% !important' }}
                >
                  {league?.is_tournament
                    ? league?.leagues?.map(l => l.teams)?.flat().length
                    : league.divisions?.map(l => l.teams)?.flat().length || league.teams?.length}
                </TableCell>
                <TableCell
                  style={{ textAlign: 'center', width: '10% !important' }}
                >
                  ${league.new_product?.team_price}
                </TableCell>
                <TableCell
                  style={{ textAlign: 'center', width: '5% !important' }}
                >
                  <Link
                    href={`${
                      league.is_tournament ? '/tournament' : '/league'
                    }/${league.ID}`}
                    target="_blank"
                  >
                    <IconButton style={{ padding: 5 }}>
                      <OpenInNewIcon
                        fontSize="small"
                        style={{
                          color: colors.blue_strong,
                        }}
                      />
                    </IconButton>
                  </Link>
                  <IconButton onClick={e => duplicateLeague(league)}>
                    <CopyIcon color="primary" fontSize="small" />
                  </IconButton>
                  {is_admin_or_manager && !league.teams?.length && (
                    <IconButton
                      className={classes.root}
                      onClick={() =>
                        deleteLeague(league.ID, league.is_tournament)
                      }
                    >
                      <DeleteIcon
                        fontSize="small"
                        style={{
                          color: colors.red_strong,
                        }}
                      />
                    </IconButton>
                  )}
                </TableCell>
              </TableRow>
            ))}
        </TableBody>
      </Table>
    </div>
  )
}

const mapStateToProps = (state, ownProps) => ({
  leagues: state.league.leagues
    .concat(state.league.tournaments.map(t => ({ ...t, is_tournament: true })))
    .filter(l =>
      applyFilter(
        {
          name: l.name,
          gender: l.gender,
          age_group: l.age_group.name,
        },
        state.league.filter.query
      )
    ),
  is_admin: state.user.user_type === 'admin',
  is_admin_or_manager: state.user.is_admin_or_manager,
  hide_paid: state.league.filter.hide_paid,
})

const mapDispatchToProps = (dispatch, ownProps) => ({
  gotoLeague: id => dispatch(push('/league/' + id)),
  duplicateLeague: league => {
    dispatch(setLeagueDetails(league))
    dispatch(toggleModal(true, false))
  },
  editLeague: league => {
    dispatch(setLeagueDetails(league))
    dispatch(toggleModal(true, false, true))
  },
  deleteLeague: (id, is_tournament) =>
    dispatch(openDeleteModal('league', () => deleteLeague(id, is_tournament))),
  getLeagues: f => dispatch(getLeagues(f)),
  openHubspotList: league_ids => dispatch(openHubspotList({ league_ids })),
})

export default connect(mapStateToProps, mapDispatchToProps)(LeaguesTable)
