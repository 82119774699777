import React from 'react'
import { connect } from 'react-redux'
import axios from 'axios'
import { ERP_BACKEND_URL } from './../../consts'

import Select from 'react-select'
import Grid from '@material-ui/core/Grid'
import Button from '@material-ui/core/Button'
import FormControl from '@material-ui/core/FormControl'
import InputLabel from '@material-ui/core/InputLabel'
import InputAdornment from '@material-ui/core/InputAdornment'
import Input from '@material-ui/core/Input'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogTitle from '@material-ui/core/DialogTitle'
import IconButton from '@material-ui/core/IconButton'
import CloseIcon from '@material-ui/icons/Close'
import Autocomplete from '@material-ui/lab/Autocomplete'
import TextField from '@material-ui/core/TextField'
import SquarePayment from './SquarePayment'
import SquareTerminal from './SquareTerminal'

import { dispatchError, openConfirmModal } from 'common/actions'
import { searchFacilityCustomers } from 'customer/actions'
import { addPayment } from './../actions'
import { actions } from './../redux'
import { payment_options } from 'consts'

class NewPaymentForm extends React.Component {
  constructor(props) {
    super(props)
    this.state = { loading: false, manual_card: true, idempotency_key: crypto.randomUUID()}
  }

  shouldChargeAmount() {
    return this.props.amount <= this.props.invoice.balance
  }
  reload_idempotency_key() {
    this.setState({idempotency_key: crypto.randomUUID()})
  }
  cardNonceResponseReceived(nonce) {
    if (!(this.props.amount > 0)) {
      return this.props.dispatchError({ message: 'Amount cannot be 0' })
    }
    if (!this.shouldChargeAmount()) {
      return this.props.dispatchError({
        message: 'Amount cannot be greater than invoice balance',
      })
    }
    this.setState({ loading: true })
    const body = {
      idempotency_key: this.state.idempotency_key,
      amount_money: {
        amount: Math.round(this.props.amount * 100),
        currency: 'USD',
      },
      buyer_email_address: this.props.customer_email,
      source_id: nonce,
      note: this.props.payment_note,
      facility_id: this.props.facility_id,
    }
    return axios
      .post(ERP_BACKEND_URL + '/charge/event', body)
      .then(resp => {
        const square_transaction_id = resp.data
        this.props.addCardPayment(square_transaction_id)
        this.props.handleClose()
        this.reload_idempotency_key()
      })
      .catch(e => {
        console.error(e)
        this.props.handleClose()
        let err_msg = '[Square] Error charging card'
        if (e.response) {
          err_msg = e.response.data
        }
        this.props.dispatchError({ message: err_msg })
        this.setState({ loading: false })
      })
  }

  onTerminalConfirmation() {
    this.props.handleClose()
    window.location.reload();
  }

  render() {
    return (
      <Dialog
        open={this.props.open}
        onClose={() => this.props.handleClose()}
        aria-labelledby="form-dialog-title"
        maxWidth="sm"
        disableBackdropClick={true}
        fullWidth
      >
        <DialogTitle id="form-dialog-title">
          Add new payment
          <IconButton
            style={{
              cursor: 'pointer',
              float: 'right',
            }}
            onClick={() => this.props.handleClose()}
          >
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent>
          <Grid
            container
            direction="row"
            justify="center"
            alignItems="center"
            style={{ textAlign: 'center' }}
            spacing={4}
          >
            {this.props.is_league && (
              <Grid item xs={12}>
                <Select
                  options={this.props.league_customers}
                  placeholder="League player"
                  value={
                    this.props.player_id &&
                    this.props.league_customers.find(
                      f => f.value === this.props.player_id
                    )
                  }
                  onChange={e => this.props.updatePayment('player_id', e.value)}
                  styles={{ menuPortal: base => ({ ...base, zIndex: 9999 }) }}
                  menuPortalTarget={document.body}
                />
              </Grid>
            )}
            <Grid item xs={12}>
              <Select
                options={payment_options}
                placeholder="Payment method"
                value={
                  this.props.method &&
                  payment_options.find(f => f.value === this.props.method)
                }
                onChange={e => this.props.updatePayment('method', e.value)}
                styles={{ menuPortal: base => ({ ...base, zIndex: 9999 }) }}
                menuPortalTarget={document.body}
              />
            </Grid>
            {this.props.method && (
              <Grid item xs={12}>
                <FormControl fullWidth>
                  <InputLabel htmlFor="standard-adornment-amount">
                    Amount
                  </InputLabel>
                  <Input
                    id="standard-adornment-amount"
                    value={this.props.amount}
                    onChange={e =>
                      this.props.updatePayment('amount', e.target.value)
                    }
                    startAdornment={
                      <InputAdornment position="start">$</InputAdornment>
                    }
                  />
                </FormControl>
              </Grid>
            )}
            {this.props.method === 'check' && (
              <Grid item xs={12}>
                <FormControl fullWidth>
                  <InputLabel htmlFor="standard-adornment-amount">
                    Check number
                  </InputLabel>
                  <Input
                    id="standard-adornment-amount"
                    value={this.props.check_number}
                    onChange={e =>
                      this.props.updatePayment('check_number', e.target.value)
                    }
                  />
                </FormControl>
              </Grid>
            )}
            {this.props.method === 'wire' && (
              <Grid item xs={12}>
                <FormControl fullWidth>
                  <InputLabel htmlFor="standard-adornment-amount">
                    Transaction number
                  </InputLabel>
                  <Input
                    id="standard-adornment-amount"
                    value={this.props.transaction_number}
                    onChange={e =>
                      this.props.updatePayment(
                        'transaction_number',
                        e.target.value
                      )
                    }
                  />
                </FormControl>
              </Grid>
            )}
            {this.props.method === 'credit' && (
              <Grid item xs={12}>
                <Autocomplete
                  options={this.props.credit_customers}
                  getOptionLabel={option =>
                    `${option.first_name} ${option.last_name} (${option.email})`
                  }
                  renderOption={option => (
                    <p>
                      {option.first_name} {option.last_name}{' '}
                      {option.hubspot && option.hubspot.gender && (
                        <span>({option.hubspot.gender})</span>
                      )}
                      <br />
                      <span>
                        email=
                        {option.email}
                      </span>
                      {option.hubspot && option.hubspot.phone && (
                        <span>
                          <br />
                          phone=
                          {option.hubspot.phone}
                        </span>
                      )}
                      {option.hubspot && option.hubspot.state && (
                        <span>
                          <br />
                          state=
                          {option.hubspot.state}
                        </span>
                      )}
                    </p>
                  )}
                  renderInput={params => (
                    <TextField
                      {...params}
                      label="Customer"
                      variant="outlined"
                      fullWidth
                      onChange={e =>
                        this.props.searchCustomers(
                          e.target.value,
                          this.props.facility_id
                        )
                      }
                    />
                  )}
                  value={this.props.customer}
                  onChange={(e, c) => this.props.updatePayment('customer', c)}
                  fullWidth
                />
              </Grid>
            )}
            {this.props.method === 'card' && (
              <Grid item xs={12}>
                <SquarePayment
                  onTokenObtained={this.cardNonceResponseReceived.bind(this)}
                />
              </Grid>
            )}
            {this.props.method === 'terminal' && (
              <Grid item xs={12}>
                <SquareTerminal
                  amount={this.props.amount}
                  onTerminalConfirmation = {() => this.onTerminalConfirmation()}
                />
              </Grid>
            )}
          </Grid>
        </DialogContent>
        {!(this.props.method === 'card') && !(this.props.method === 'terminal') && (
          <DialogActions>
            <Button
              disabled={this.props.is_league && !this.props.player_id}
              onClick={() => {
                if (!this.shouldChargeAmount()) {
                  return this.props.dispatchError({
                    message: 'Amount cannot be greater than invoice balance',
                  })
                }
                this.props.addPayment(null, this.props.method)
                this.props.handleClose()
              }}
            >
              Save
            </Button>
          </DialogActions>
        )}
      </Dialog>
    )
  }
}

const mapStateToProps = (state, ownProps) => ({
  ...state.invoice.payment_form,
  customer_email: ownProps.is_contract
    ? state.contract.contract.customer.email
    : state.calendar.event_details.customer &&
      state.calendar.event_details.customer.email,
  payment_note: ownProps.is_contract
    ? `hydra_contract_${state.contract.contract.ID}`
    : `hydra_booking_${state.calendar.event_details.ID}`,
  facility_id: state.invoice.invoice?.facility_id,
  open: state.invoice.payment_modal_open,
  is_league: state.invoice.invoice && state.invoice.invoice.is_league,
  league_customers:
    state.invoice.invoice &&
    state.invoice.invoice.team_id &&
    state.invoice.invoice.team.roster.map(r => ({
      label: `${r.customer.first_name} ${r.customer.last_name} (${r.customer.email})`,
      value: r.customer.ID,
    })),
  invoice: state.invoice.invoice,
  credit_customers: state.customer.customers,
  user_type:state.user.user_type,
  user_facility_id:state.user.facility_id,
})

const mapDispatchToProps = (dispatch, ownProps) => ({
  updatePayment: (k, v) =>
    dispatch(actions.updatePayment({ field: k, value: v })),
  handleClose: () => dispatch(actions.togglePaymentModal({ open: false })),
  dispatchError: e => dispatch(dispatchError(e)),
  searchCustomers: (q, facility_id) =>
    dispatch(searchFacilityCustomers(q, facility_id)),
    addPayment: (square_transaction_id, ptype) => {
      dispatch(
        openConfirmModal(
          'Payment',
          `add this ${ptype.toUpperCase()} payment`,
          () => addPayment(square_transaction_id)
        )
      )
    },
    addCardPayment: (square_transaction_id) => {
      dispatch(
        addPayment(square_transaction_id)
      )
    },
    reloadInvoice: () => dispatch(actions._reloadInvoice()),
})

export default connect(mapStateToProps, mapDispatchToProps)(NewPaymentForm)
