import React from 'react'
import { connect } from 'react-redux'

import Grid from '@material-ui/core/Grid'
import Paper from '@material-ui/core/Paper'
import CreateLeagueForm from './CreateRolloverLeague'

class Calendar extends React.Component {
  componentDidMount() {
  }

  render() {
    const { is_tournament } = this.props
    return (
      <Paper elevation={0} style={{ padding: '3%' }}>
        <Grid
          container
          direction="row"
          justify="flex-start"
          alignItems="center"
          spacing={3}
        >
          <Grid item xs={12} style={{ textAlign: 'center', width: '100%' }}>
            <CreateLeagueForm no_facility is_tournament={is_tournament} />
          </Grid>
        </Grid>
      </Paper>
    )
  }
}

const mapStateToProps = (state, ownProps) => ({})

const mapDispatchToProps = (dispatch, ownProps) => ({
})

export default connect(mapStateToProps, mapDispatchToProps)(Calendar)
