import React from 'react'
import { connect } from 'react-redux'
import axios from 'axios'
import { ERP_BACKEND_URL } from './../../../consts'
import { addPayment } from './../../../invoice/actions'
import {
  DialogActions,
  DialogContent,
  DialogTitle,
  Dialog,
  Grid,
  Typography,
} from '@material-ui/core'
import Select from 'react-select'
import CloseIcon from '@material-ui/icons/Close'

import { Button, IconButton } from '@material-ui/core'
import SquarePayment from './../../../invoice/components/SquarePayment'

import { actions } from '../../redux'
import { getNewProductPricesByProductType } from '../../actions'
import { dispatchError, changeLoading } from 'common/actions'
import { payment_options_only_card_and_terminal } from 'consts'
import SquareTerminal from 'invoice/components/SquareTerminal'

import FormControlLabel from '@material-ui/core/FormControlLabel'
import Checkbox from '@material-ui/core/Checkbox'
class AddMembership extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      idempotency_key: crypto.randomUUID(),
    }
  }
  getOptions() {
    if (!this.props.customer) return []
    let options = [
      {
        label: `${this.props.customer.first_name} ${this.props.customer.last_name}`,
        value: this.props.customer.ID,
        family_id: 0,
        facility_id: this.props.customer.facility_id,
      },
    ]

    for (const m of this.props.family) {
      if (m.child?.ID == this.props.customer?.ID) {
        options.push({
          label: `${m.parent.first_name} ${m.parent.last_name} (${m.relation})`,
          value: m.parent_id,
          family_id: m.ID,
          facility_id: m.child.facility_id,
        })
      } else if (m.parent?.ID == this.props.customer?.ID) {
        options.push({
          label: `${m.child.first_name} ${m.child.last_name} (${m.relation})`,
          value: m.child_id,
          family_id: m.ID,
          facility_id: m.child.facility_id,
        })
      }
    }
    return options
  }
  createFreeMembership = () => {
    this.props.setLoading(true)
    const body = {
      is_free: this.props.freeMembership,
      family_id: this.props.family_id,
      facility_id: this.props.facility_id,
      new_product_id: this.props.product_id,
      customer_id: this.props.family_member_id,
    }
    return axios
      .post(ERP_BACKEND_URL + '/membership', body)
      .then(resp => {
        this.setState({ loading: false })
        window.location.reload()
      })
      .catch(e => {
        console.error(e)
        this.props.handleClose()
        let err_msg = '[Square] Error charging card'
        if (e.response) {
          err_msg = e.response.data
        }
        this.props.dispatchError({ message: err_msg })
        this.props.setLoading(false)
      })
  }
  reload_idempotency_key() {
    this.setState({ idempotency_key: crypto.randomUUID() })
  }
  cardNonceResponseReceived(nonce) {
    const p = this.props.product
    if (!(p.amount > 0)) {
      return this.props.dispatchError({ message: 'Amount cannot be 0' })
    }
    this.props.setLoading(true)
    const base_price = p.invoice_price,
      fees =
        ((p.fees?.reduce((a, b) => a + b.value, 0) || 0) * base_price) / 100,
      taxes =
        ((p.taxes?.reduce((a, b) => a + b.value, 0) || 0) * base_price) / 100
    const body = {
      idempotency_key: this.state.idempotency_key,
      is_free: this.props.freeMembership,
      family_id: this.props.family_id,
      customer_id: this.props.family_member_id,
      facility_id: this.props.facility_id,
      new_product_id: this.props.product_id,
      price: base_price,
      paid: p.amount,
      fees,
      taxes,
      charge: {
        amount_money: {
          amount: parseInt(p.amount * 100),
          currency: 'USD',
        },
        buyer_email_address: this.member?.customer?.email,
        source_id: nonce, //
        facility_id: this.props.facility_id,
      },
    }

    return axios
      .post(ERP_BACKEND_URL + '/membership', body)
      .then(resp => {
        window.location.reload()
        this.props.setLoading(false)
      })
      .catch(e => {
        console.error(e)
        this.props.handleClose()
        let err_msg = '[Square] Error charging card'
        if (e.response) {
          err_msg = e.response.data
        }
        this.props.dispatchError({ message: err_msg })
        this.props.setLoading(true)
      })
  }
  render() {
    let options = this.getOptions()
    return (
      <Dialog
        open={this.props.open_create}
        onClose={this.props.handleClose}
        aria-labelledby="form-dialog-title"
        maxWidth="800"
      >
        <DialogTitle id="form-dialog-title">
          Add new membership
          <IconButton
            style={{
              cursor: 'pointer',
              float: 'right',
            }}
            onClick={this.props.handleClose}
          >
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent style={{ padding: '10px' }}>
          <Grid>
            <Grid item fullWidth style={{ padding: '20px' }}>
              <Select
                options={options}
                placeholder="Select Family member..."
                styles={{ menuPortal: base => ({ ...base, zIndex: 9999 }) }}
                onChange={e => {
                  this.props.updateForm('family_member_id', e.value)
                  this.props.updateForm('facility_id', this.props.customer.facility_id)
                  this.props.updateForm('family_id', e.family_id)
                  this.props.getMembershipPrices(18, this.props.customer.facility_id)
                }}
                menuPortalTarget={document.body}
                variant="outlined"
                fullWidth
              />
            </Grid>
            {this.props.family_member_id >= 0 && (
              <Grid item md={12} style={{ padding: '15px' }}>
                <Select
                  options={this.props.facilities}
                  placeholder="Select facility *"
                  value={this.props.facility_id && this.props.facilities.find(f => f.value === this.props.facility_id)}
                  onChange={e => {
                    this.props.updateForm('facility_id', e.value)
                    this.props.getMembershipPrices(18, e.value)
                  }}
                  styles={{ menuPortal: base => ({ ...base, zIndex: 9999 }) }}
                  menuPortalTarget={document.body}
                  variant="outlined"
                  fullWidth
                />
              </Grid>
            )}
            {this.props.facility_id && (
              <Grid item xs={12} style={{ padding: '15px' }}>
                <Select
                  options={this.props.membership_prices}
                  placeholder="Membership"
                  value={
                    this.props.product_id &&
                    this.props.membership_prices.find(
                      f => f.value === this.props.product_id
                    )
                  }
                  onChange={e => {
                    this.props.updateForm('product_id', e.value)
                    this.props.updateForm('product', e.product)
                  }}
                  styles={{ menuPortal: base => ({ ...base, zIndex: 9999 }) }}
                  menuPortalTarget={document.body}
                />
              </Grid>
            )}
            {this.props.facility_id && this.props.is_admin && (
              <Grid item xs={12} style={{ padding: '15px' }}>
                <FormControlLabel
                  control={
                    <Checkbox
                      value="primary"
                      inputProps={{ 'aria-label': 'primary checkbox' }}
                      checked={this.props.freeMembership}
                      onChange={e => this.props.updateForm('freeMembership', e.target.checked)}
                    />
                  }
                  label="Free Membership"
                />
              </Grid>
            )}
            {!this.props.freeMembership && this.props.product_id && (
              <Grid item xs={12} style={{ padding: '15px' }}>
                <Select
                  options={payment_options_only_card_and_terminal}
                  placeholder="Payment method"
                  value={
                    this.props.method &&
                    payment_options_only_card_and_terminal.find(
                      f => f.value === this.props.method
                    )
                  }
                  onChange={e => {
                    this.props.updateForm('method', e.value)
                  }}
                  styles={{ menuPortal: base => ({ ...base, zIndex: 9999 }) }}
                  menuPortalTarget={document.body}
                />
              </Grid>
            )}
            {!this.props.freeMembership && this.props.method === 'card' && (
              <Grid item xs={12}>
                <Typography
                  color="primary"
                  style={{ textAlign: 'center', marginTop: 10 }}
                >
                  Amount: {this.props?.product?.amount}$
                </Typography>
                <SquarePayment
                  onTokenObtained={this.cardNonceResponseReceived.bind(this)}
                />
              </Grid>
            )}

            {!this.props.freeMembership && this.props.method === 'terminal' && (  
              <Grid item xs={12}>
                <Typography
                  color="primary"
                  style={{ textAlign: 'center', marginTop: 10 }}
                >
                  Amount: {this.props?.product?.amount}$
                </Typography>

                {this.props?.product?.amount > 0 && 
                <Grid item xs={12} style={{width:'100%', justifyContent:'center', display:'flex'}}>
                  <SquareTerminal
                    amount={this.props?.product?.amount}
                    onTerminalConfirmation = {() => window.location.reload()}
                    forMembership={true}
                  />
                </Grid>
                }
              </Grid>
            )}
          </Grid>
        </DialogContent>
        <DialogActions>
          {this.props.freeMembership && (
            <Button onClick={this.createFreeMembership} color="primary">
              Save
            </Button>
          )}
        </DialogActions>
      </Dialog>
    )
  }
}

const mapStateToProps = (state, ownProps) => ({
  ...state.customer.membership_dialog,
  customer: state.customer.customer,
  family: state.customer.family,
  is_admin: state.user.is_admin,
  facilities: state.common.filter_facilities,
  membership_prices: state.customer.membership_prices.map(price => {
    return {
      label: `${price.product.name} - ${price.invoice_price} $`,
      value: price.product.ID,
      product: price,
    }
  }),
})

const mapDispatchToProps = (dispatch, ownProps) => ({
  updateForm: (k, v) =>
    dispatch(actions.updateMembershipDialog({ key: k, value: v })),
  getMembershipPrices: (productType, facilityID) =>
    dispatch(getNewProductPricesByProductType(productType, facilityID)),
  handleClose: () =>
    dispatch(
      actions.updateMembershipDialog({ key: 'open_create', value: false })
    ),
  addCardPayment: square_transaction_id => {
    dispatch(addPayment(square_transaction_id))
  },
  dispatchError: e => dispatch(dispatchError(e)),
  setLoading: loading => dispatch(changeLoading(loading)),
})

export default connect(mapStateToProps, mapDispatchToProps)(AddMembership)
