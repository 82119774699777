import React from 'react'
import { connect } from 'react-redux'
import moment from 'moment'

import ProfileIcon from '@material-ui/icons/AssignmentInd'
import Grid from '@material-ui/core/Grid'
import Paper from '@material-ui/core/Paper'
import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TableCell from '@material-ui/core/TableCell'
import TableHead from '@material-ui/core/TableHead'
import { Button } from '@material-ui/core'
import TableRow from '@material-ui/core/TableRow'
import IconButton from '@material-ui/core/IconButton'
import EditIcon from '@material-ui/icons/Edit'

import { openInNewTab } from './../../../helper'
import { unixToDateTime } from 'helper'
import { changeLoading } from 'common/actions'
import { actions } from './../../redux'

const Membership = props => {
  let { customer, memberships } = props
  return (
    <Grid
      container
      direction="row"
      justify="center"
      alignItems="flex-start"
      spacing={4}
    >
      <Grid item xs={12} style={{ textAlign: 'center' }}>
        <Button
          className="save-btn"
          style={{ marginLeft: 10 }}
          onClick={() => {
            props.openAddMembership(true)
          }}
        >
          Add Membership
        </Button>
        <Paper elevation={0} style={{ marginTop: '10px' }}>
          <Table aria-label="simple table">
            <TableHead>
              <TableRow>
                <TableCell
                  style={{ textAlign: 'center' }}
                  className="table-header"
                >
                  Buy Date
                </TableCell>
                <TableCell
                  style={{ textAlign: 'center' }}
                  className="table-header"
                >
                  Expiration Date
                </TableCell>
                <TableCell
                  style={{ textAlign: 'center' }}
                  className="table-header"
                >
                  Holder fullname
                </TableCell>
                <TableCell
                  style={{ textAlign: 'center' }}
                  className="table-header"
                >
                  Go To Holder
                </TableCell>
                <TableCell
                  style={{ textAlign: 'center' }}
                  className="table-header"
                >
                  Edit Membership
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {memberships?.map(membership => {
                return (
                  <TableRow key={membership.ID}>
                    <TableCell style={{ textAlign: 'center' }}>
                      {moment(membership.CreatedAt).format('MMMM D, YYYY')}
                    </TableCell>
                    <TableCell style={{ textAlign: 'center' }}>
                      {unixToDateTime(membership.valid_until).format(
                        'MMMM D, YYYY'
                      )}
                    </TableCell>
                    <TableCell style={{ textAlign: 'center' }}>
                      {`${membership.holder?.first_name} ${membership.holder?.last_name}`}
                    </TableCell>
                    <TableCell style={{ textAlign: 'center' }}>
                      {(membership.holder && membership.holder.ID !== customer.customer?.ID) && (
                        <IconButton
                          onClick={() =>
                            openInNewTab(`/customer/${membership.holder?.ID}`)
                          }
                        >
                          <ProfileIcon color="primary" fontSize="small" />
                        </IconButton>
                      )}
                    </TableCell>
                    <TableCell style={{ textAlign: 'center' }}>
                      {props.is_admin || props.is_manager ? (
                        <IconButton
                          style={{ padding: 1 }}
                          onClick={() => {
                            props.setMembership(membership)
                          }}
                        >
                          <EditIcon color="primary" fontSize="small" />
                        </IconButton>
                      ) : (
                        <></>
                      )}
                    </TableCell>
                  </TableRow>
                )
              })}
            </TableBody>
          </Table>
        </Paper>
      </Grid>
    </Grid>
  )
}

const mapStateToProps = (state, ownProps) => {
  const customer = state.customer;

  // console.log('customer', customer);
  const family_memberships = customer.family.reduce((acc, family) => {
    if (family.child.ID === customer?.customer?.ID) {
      acc = family.parent.member?.memberships ? acc.concat(family.parent.member.memberships) : acc;
      return acc;
    }
    else if (family.parent.ID === customer?.customer?.ID) {
      acc = family.child.member?.memberships ? acc.concat(family.child.member.memberships) : acc;
      return acc;
    }
    return acc;
  }, []);
  
  let memberships = [];

  customer?.customer?.member?.memberships?.forEach(m => {
    const holder_id = m.sub_customer_id;
    let holder = null
    if (customer.customer.ID === holder_id) {
      holder = customer.customer;
    }
    else {
      holder = customer.family.find(family => family.child.ID === holder_id)?.child || customer.family.find(family => family.parent.ID === holder_id)?.parent;
    }
    if (holder) {
      memberships.push({...m, holder: holder});
    }
  });
  
  family_memberships.forEach(m => {
    const holder_id = m.sub_customer_id;
    const holder = customer.family.find(family => family.child.ID === holder_id)?.child || 
                   customer.family.find(family => family.parent.ID === holder_id)?.parent;
    if (holder) {
      memberships.push({...m, holder: holder});
    }
  });
  
  const seen = new Set();
  const uniqueMemberships = [];
  
  memberships.forEach(m => {
    if (!seen.has(m.ID)) {
      seen.add(m.ID);
      uniqueMemberships.push(m);
    }
  });
  uniqueMemberships.sort((a, b) => new Date(b.valid_until) - new Date(a.valid_until));

  return {
    is_admin: state.user.is_admin,
    is_manager: state.user.user_type === 'manager',
    customer,
    memberships: uniqueMemberships,
  }
}

const mapDispatchToProps = (dispatch, ownProps) => ({
  changeLoading: loading => dispatch(dispatch(changeLoading(loading))),
  setMembership: membership => {
    dispatch(
      actions.updateMembershipDialog({ key: 'membership', value: membership })
    )
    dispatch(
      actions.updateMembershipDialog({ key: 'open_update', value: true })
    )
  },
  openAddMembership: () => {
    dispatch(
      actions.updateMembershipDialog({ key: 'open_create', value: true })
    )
  },
})

export default connect(mapStateToProps, mapDispatchToProps)(Membership)
