import React from 'react'
import { connect } from 'react-redux'
import Button from '@material-ui/core/Button'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogTitle from '@material-ui/core/DialogTitle'
import Grid from '@material-ui/core/Grid'
import IconButton from '@material-ui/core/IconButton'
import CloseIcon from '@material-ui/icons/Close'
import DivisionTable from './ChoseDivisionTable'
import SettingsPage from './Settings'
import { openConfirmModal } from 'common/actions'

import { leagueSlice } from 'league/redux'
import {createRolloverLeagues} from 'league/actions'

class RolloverDialog extends React.Component {
    render() {
      const { open, handleClose } = this.props;
      const tabs = ['season','settings','dates','name','divisions','save'];
      return (
        <Dialog
            open={open}
            onClose={handleClose}
            aria-labelledby="form-dialog-title"
            fullWidth
            maxWidth="lg"
        >
          <DialogTitle id="form-dialog-title" style={{ paddingBottom: 0 }}>
            Rollover
            <IconButton
              style={{
                cursor: 'pointer',
                float: 'right',
              }}
              onClick={handleClose}
            >
              <CloseIcon />
            </IconButton>
          </DialogTitle>
          <DialogContent>
                {this.props.current_tab === "divisions" ? <DivisionTable /> : <SettingsPage />}
          </DialogContent>
          <DialogActions>
            {tabs.indexOf(this.props.current_tab) > 0 && (
              <Button
                onClick={() => {
                  this.props.changeTab(tabs[tabs.indexOf(this.props.current_tab) - 1]);
                }}
                color="primary"
              >
                Prev
              </Button>
            )}
            {tabs.indexOf(this.props.current_tab) < tabs.length - 1 && (
              <Button
                onClick={() => {
                  this.props.changeTab(tabs[tabs.indexOf(this.props.current_tab) + 1]);
                }}
                color="primary"
              >
                Next
              </Button>
            )}
            {this.props.current_tab === "save" && (
              <Button
                onClick={() => {
                  this.props.createRolloverLeagues();
                  this.props.handleClose();
                }}
                color="primary"
              >
                Save
              </Button>
            )}
          </DialogActions>
        </Dialog>
      );
    }
}

const mapStateToProps = (state, ownProps) => {
  return {
    open: state.league.rollover.open,
    current_tab: state.league.rollover.current_tab,
  }
}

const mapDispatchToProps = (dispatch, ownProps) => ({
  handleClose: () => dispatch(leagueSlice.actions.updateRolloverDialog({ key: 'open', value: false })),
  changeTab: tab => dispatch(leagueSlice.actions.updateRolloverDialog({ key: 'current_tab', value: tab })),
  createRolloverLeagues: () => dispatch(createRolloverLeagues()),
})

export default connect(mapStateToProps, mapDispatchToProps)(RolloverDialog)
