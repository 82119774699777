import React from 'react'
import { connect } from 'react-redux'
import { push } from 'connected-react-router'

import { withStyles } from '@material-ui/core/styles'
import Link from '@material-ui/core/Link'
import Table from '@material-ui/core/Table'
import TableHead from '@material-ui/core/TableHead'
import TableBody from '@material-ui/core/TableBody'
import TableCell from '@material-ui/core/TableCell'
import TableRow from '@material-ui/core/TableRow'
import Paper from '@material-ui/core/Paper'
import IconButton from '@material-ui/core/IconButton'
import GroupIcon from '@material-ui/icons/Group'
import Checkbox from '@material-ui/core/Checkbox'


import { leagueSlice } from 'league/redux'


const styles = theme => ({
  maxheight: {
    maxHeight: 650,
    overflow: 'scroll',
  },
  root: {
    padding: 2,
  },
})

class TeamTable extends React.Component {
  render() {
    const { classes, leagues, rollover_open, selected_leagues } = this.props;
    return (
      <Paper elevation={0}>
        <Table>
          <TableHead>
            <TableRow>
              {rollover_open &&
              <TableCell style={{ textAlign: 'center', width: '10%' }}>
                <Checkbox
                  checked={selected_leagues.size === leagues.length}
                  indeterminate={selected_leagues.size > 0 && selected_leagues.size < leagues.length}
                  onChange={this.props.handleRolloverSelectAll}
                />
              </TableCell>
              }
              <TableCell
                style={{ textAlign: 'center', width: '40%' }}
                className="table-header"
              >
                Name
              </TableCell>
              <TableCell
                style={{ textAlign: 'center', width: '25%' }}
                className="table-header"
              >
                # of teams
              </TableCell>
              <TableCell
                style={{ textAlign: 'center', width: '25%' }}
                className="table-header"
              >
                Action
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {leagues.map(league => (
              <TableRow key={league.ID} style={{ cursor: 'pointer' }}>
                {rollover_open &&
                <TableCell style={{ textAlign: 'center', width: '10%' }}>
                  <Checkbox
                    checked={selected_leagues.includes(league.ID)}
                    onChange={() => this.props.handleRolloverSelectLeague(league.ID)}
                  />
                </TableCell>
                }
                <TableCell
                  style={{ textAlign: 'center', width: '25% !important' }}
                >
                  {league.name}
                </TableCell>
                <TableCell
                  style={{ textAlign: 'center', width: '20% !important' }}
                >
                  {league.teams?.length}
                </TableCell>
                <TableCell style={{ textAlign: 'center', width: '20%' }}>
                  <Link href={`/league/${league.ID}`} target="_blank">
                    <IconButton className={classes.root}>
                      <GroupIcon color="primary" fontSize="small" />
                    </IconButton>
                  </Link>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </Paper>
    );
  }
}


const mapStateToProps = (state, ownProps) => ({
  leagues: state.league.league.leagues || state.league.league.divisions,
  rollover_open: state.league.rollover.open,
  selected_leagues: state.league.rollover.selected_leagues,
})

const mapDispatchToProps = (dispatch, ownProps) => ({
  gotoTeam: id => dispatch(push('/team/' + id)),
  handleRolloverSelectLeague: (leagueId) => dispatch(leagueSlice.actions.handleRolloverSelectLeague({id:leagueId})),
  handleRolloverSelectAll: () => dispatch(leagueSlice.actions.handleRolloverSelectAll({})),
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(styles)(TeamTable))