export const isDev = process.env.REACT_APP_ENV === 'staging'

export const BACKEND_URL = 'https://app.scoreplay.io'
// export const BACKEND_URL = 'http://localhost:8080'
export const SCOREBOARDS_SOCKET_URL = 'wss://harvey.sofive.com/ws'
export const SCOREBOARDS_SERVER_URL = 'https://harvey.sofive.com/send'
export const ERP_BACKEND_URL = isDev
  ? 'https://erp.integration.sofive.com/api'
  // ? 'http://localhost:8080/api'
  : 'https://erp.sofive.com/api'
export const ERP_BACKEND_NO_USER_URL = isDev
  ? 'https://erp.integration.sofive.com'
  // ? 'http://localhost:8080'
  : 'https://erp.sofive.com'
export const SQUARE_APPLICATION_ID = isDev
  ? 'sandbox-sq0idb-hU7CHRCmfVUnwy3LEeB2nA'
  : 'sq0idp-rotdbz3GXCVJiurjb7nASQ'
export const SQUARE_LOCATION_ID = isDev ? 'T08GQZDWXRC8P' : 'TVYD099NR4G5Z'

export const routes = {
  HOME: { path: '/', exact: true },
  ADMIN: { path: '/admin', exact: true },
  LOGIN: { path: '/login', exact: true },
  SIGNUP: { path: '/signup', exact: true },
  FORGOT: { path: '/forgot', exact: true },
  SCOREBOARDS: { path: '/scoreboards', exact: true },
  RESOURCES: { path: '/resources', exact: true },
  CALENDAR: {
    CAL: { path: '/calendar', exact: true },
    EVENT: { path: '/calendar/event/:id', exact: true },
  },
  CUSTOMER: {
    SEARCH: { path: '/customer', exact: true },
    PROFILE: { path: '/customer/:id', exact: true },
  },
  PRODUCTS: { path: '/products', exact: true },
  LEAGUES: {
    LIST: { path: '/leagues', exact: true },
    DETAILS: { path: '/league/:id', exact: true },
    TOURNAMENT_DETAILS: { path: '/tournament/:id', exact: true },
  },
  TEAMS: {
    LIST: { path: '/teams', exact: true },
    DETAILS: { path: '/team/:id', exact: true },
  },
  INVOICE: {
    LIST: { path: '/invoice', exact: true },
    DETAILS: { path: '/invoice/:id', exact: true },
  },
  REPORTING: {
    HOME: { path: '/reporting', exact: true },
    SALES: { path: '/reporting/sales', exact: true },
    EVENTS: { path: '/reporting/events', exact: true },
    PAYMENTS: { path: '/reporting/payments', exact: true },
    HEALTH: { path: '/reporting/health', exact: true },
    DAILY: { path: '/reporting/daily', exact: true },
    OUTSTANDING: { path: '/reporting/outstanding', exact: true },
    TEAMS: { path: '/reporting/teams', exact: true },
    RECONCILIATION: { path: '/reporting/reconciliation', exact: true },
    CAMERAS: { path: '/reporting/cameras', exact: true },
    TRIALS: { path: '/reporting/trials', exact: true },
  },
  CONTRACT: {
    LIST: { path: '/contract', exact: true },
    DETAILS: { path: '/contract/:id', exact: true },
  },
  DISCOUNTS: { path: '/discount', exact: true },
  SOCCERNAUT: { path: '/soccernaut', exact: true },
  STAFF: { path: '/staff', exact: true },
  PICKUP: {
    LIST: { path: '/pickup', exact: true },
    DETAILS: { path: '/pickup/:id', exact: true },
  },
  REFEREE: {
    HOME: { path: '/referee', exact: true },
  },
  CLASSES: {
    LIST: { path: '/classes', exact: true },
    DETAILS: { path: '/classes/:id', exact: true },
  },
  MEDIA: {
    LIST: { path: '/media', exact: true },
  },
}

export const GLOBAL_DATE_FORMAT = 'MM/DD/YYYY'

export const colors = {
  red_strong: '#F64E60',
  red_mild: '#FFE2E5',
  green_strong: '#03783a',
  green_mild: '#C9F7F5',
  purple_strong: '#8950FC',
  purple_mild: '#EEE5FF',
  yellow_strong: '#FFA800',
  yellow_mild: '#FFF4DE',
  blue_strong: '#6993FF',
  blue_mild: '#E1E9FF',
}

export const event_colors = {
  2: '#b2fb90',
  1: '#fede54',
  3: '#93aafd',
  4: '#93aafd',
  8: '#93aafd',
  9: '#93aafd',
  7: '#85f7fc',
  6: '#79a1b3',
  12: '#fffd71',
  11: '#fff9d0',
  14: 'rgb(230 61 117 / 41%)',
  5: '#eab7c0',
  13: '#cccccc',
}

export const facilityMap = {
  1: 'Sofive Meadowlands',
  2: 'Sofive Elkins Park',
  3: 'Sofive Brooklyn',
  4: 'Sofive Rockville',
  5: 'Sofive Columbia',
}
export const videosMap = {
  1: 'Meadowlands',
  2: 'Elkins Park',
  3: 'Brooklyn',
  4: 'Rockville',
  5: 'Columbia',
}

export const timezones = {
  1: "America/New_York",
  2: "America/New_York",
  3: "America/New_York",
  4: "America/New_York",
  5: "America/New_York",
  6: "America/Los_Angeles",
  7: "America/Los_Angeles",
  8: "America/Los_Angeles",
  9: "America/Los_Angeles",
  10: "America/Los_Angeles",
  11: "America/Chicago",
  12: "America/Los_Angeles",
  13: "America/Los_Angeles",
};

export const resource_types = [{ label: 'Fields', value: 'field' }]

export const payment_options = [
  { label: 'Cash', value: 'cash' },
  { label: 'Check', value: 'check' },
  { label: 'Wire Transfer / ACH', value: 'wire' },
  { label: 'Customer credit', value: 'credit' },
  { label: 'Card', value: 'card' },
  { label: 'Terminal', value: 'terminal'}
]

export const payment_options_only_card = [{ label: 'Card', value: 'card' }]

export const payment_options_only_card_and_terminal = [{ label: 'Card', value: 'card' }, { label: 'Terminal', value: 'terminal' }]
// export const payment_options_only_card_and_terminal = [{ label: 'Card', value: 'card' }]

export const genders = ['Male', 'Female', 'Co-Ed', 'Boys', 'Girls']

export const weekdays = [
  'Monday',
  'Tuesday',
  'Wednesday',
  'Thursday',
  'Friday',
  'Saturday',
  'Sunday',
]

export const day_of_week = [
  { label: 'Monday', value: 2 },
  { label: 'Tuesday', value: 3 },
  { label: 'Wednesday', value: 4 },
  { label: 'Thursday', value: 5 },
  { label: 'Friday', value: 6 },
  { label: 'Saturday', value: 7 },
  { label: 'Sunday', value: 1 },
]

export const user_types = [
  { label: 'Admin', value: 'admin' },
  { label: 'Manager', value: 'manager' },
  { label: 'MOD', value: 'mod' },
  { label: 'Coach', value: 'coach' },
  { label: 'Referee', value: 'referee' },
]

export const GAME_TYPES = [
  'regular',
  'friendly',
  '9th place consolation',
  '7th place consolation',
  '5th place consolation',
  '3rd place consolation',
  'Quarter final',
  'Semi final',
  'Final',
].map(e => ({
  value: e,
  label: `${e} game`,
}))

export const us_states = [
  'Please select State',
  'Alabama',
  'Alaska',
  'Arizona',
  'Arkansas',
  'California',
  'Colorado',
  'Connecticut',
  'Delaware',
  'District of Columbia',
  'Florida',
  'Georgia',
  'Hawaii',
  'Idaho',
  'Illinois',
  'Indiana',
  'Iowa',
  'Kansas',
  'Kentucky',
  'Louisiana',
  'Maine',
  'Maryland',
  'Massachusetts',
  'Michigan',
  'Minnesota',
  'Mississippi',
  'Missouri',
  'Montana',
  'Nebraska',
  'Nevada',
  'New Hampshire',
  'New Jersey',
  'New Mexico',
  'New York',
  'North Carolina',
  'North Dakota',
  'Ohio',
  'Oklahoma',
  'Oregon',
  'Pennsylvania',
  'Rhode Island',
  'South Carolina',
  'South Dakota',
  'Tennessee',
  'Texas',
  'Utah',
  'Vermont',
  'Virginia',
  'Washington',
  'West Virginia',
  'Wisconsin',
  'Wyoming',
]

export const countries = [
  'United States',
  'Canada',
  'Afghanistan',
  'Åland Islands',
  'Albania',
  'Algeria',
  'American Samoa',
  'Andorra',
  'Angola',
  'Anguilla',
  'Antarctica',
  'Antigua & Barbuda',
  'Argentina',
  'Armenia',
  'Aruba',
  'Ascension Island',
  'Australia',
  'Austria',
  'Azerbaijan',
  'Bahamas',
  'Bahrain',
  'Bangladesh',
  'Barbados',
  'Belarus',
  'Belgium',
  'Belize',
  'Benin',
  'Bermuda',
  'Bhutan',
  'Bolivia',
  'Bosnia & Herzegovina',
  'Botswana',
  'Bouvet Island',
  'Brazil',
  'British Indian Ocean Territory',
  'British Virgin Islands',
  'Brunei',
  'Bulgaria',
  'Burkina Faso',
  'Burundi',
  'Cambodia',
  'Cameroon',
  'Canary Islands',
  'Cape Verde',
  'Caribbean Netherlands',
  'Cayman Islands',
  'Central African Republic',
  'Ceuta & Melilla',
  'Chad',
  'Chile',
  'China',
  'Christmas Island',
  'Clipperton Island',
  'Cocos (Keeling) Islands',
  'Colombia',
  'Comoros',
  'Congo - Brazzaville',
  'Congo - Kinshasa',
  'Cook Islands',
  'Costa Rica',
  'Côte d’Ivoire',
  'Croatia',
  'Cuba',
  'Curaçao',
  'Cyprus',
  'Czechia',
  'Denmark',
  'Diego Garcia',
  'Djibouti',
  'Dominica',
  'Dominican Republic',
  'Ecuador',
  'Egypt',
  'El Salvador',
  'Equatorial Guinea',
  'Eritrea',
  'Estonia',
  'Eswatini',
  'Ethiopia',
  'Falkland Islands',
  'Faroe Islands',
  'Fiji',
  'Finland',
  'France',
  'French Guiana',
  'French Polynesia',
  'French Southern Territories',
  'Gabon',
  'Gambia',
  'Georgia',
  'Germany',
  'Ghana',
  'Gibraltar',
  'Greece',
  'Greenland',
  'Grenada',
  'Guadeloupe',
  'Guam',
  'Guatemala',
  'Guernsey',
  'Guinea',
  'Guinea-Bissau',
  'Guyana',
  'Haiti',
  'Heard & McDonald Islands',
  'Honduras',
  'Hong Kong SAR China',
  'Hungary',
  'Iceland',
  'India',
  'Indonesia',
  'Iran',
  'Iraq',
  'Ireland',
  'Isle of Man',
  'Israel',
  'Italy',
  'Jamaica',
  'Japan',
  'Jersey',
  'Jordan',
  'Kazakhstan',
  'Kenya',
  'Kiribati',
  'Kosovo',
  'Kuwait',
  'Kyrgyzstan',
  'Laos',
  'Latvia',
  'Lebanon',
  'Lesotho',
  'Liberia',
  'Libya',
  'Liechtenstein',
  'Lithuania',
  'Luxembourg',
  'Macao SAR China',
  'Madagascar',
  'Malawi',
  'Malaysia',
  'Maldives',
  'Mali',
  'Malta',
  'Marshall Islands',
  'Martinique',
  'Mauritania',
  'Mauritius',
  'Mayotte',
  'Mexico',
  'Micronesia',
  'Moldova',
  'Monaco',
  'Mongolia',
  'Montenegro',
  'Montserrat',
  'Morocco',
  'Mozambique',
  'Myanmar (Burma)',
  'Namibia',
  'Nauru',
  'Nepal',
  'Netherlands',
  'New Caledonia',
  'New Zealand',
  'Nicaragua',
  'Niger',
  'Nigeria',
  'Niue',
  'Norfolk Island',
  'North Korea',
  'North Macedonia',
  'Northern Mariana Islands',
  'Norway',
  'Oman',
  'Pakistan',
  'Palau',
  'Palestinian Territories',
  'Panama',
  'Papua New Guinea',
  'Paraguay',
  'Peru',
  'Philippines',
  'Pitcairn Islands',
  'Poland',
  'Portugal',
  'Puerto Rico',
  'Qatar',
  'Réunion',
  'Romania',
  'Russia',
  'Rwanda',
  'Samoa',
  'San Marino',
  'São Tomé & Príncipe',
  'Saudi Arabia',
  'Senegal',
  'Serbia',
  'Seychelles',
  'Sierra Leone',
  'Singapore',
  'Sint Maarten',
  'Slovakia',
  'Slovenia',
  'Solomon Islands',
  'Somalia',
  'South Africa',
  'South Georgia & South Sandwich Islands',
  'South Korea',
  'South Sudan',
  'Spain',
  'Sri Lanka',
  'St. Barthélemy',
  'St. Helena',
  'St. Kitts & Nevis',
  'St. Lucia',
  'St. Martin',
  'St. Pierre & Miquelon',
  'St. Vincent & Grenadines',
  'Sudan',
  'Suriname',
  'Svalbard & Jan Mayen',
  'Sweden',
  'Switzerland',
  'Syria',
  'Taiwan',
  'Tajikistan',
  'Tanzania',
  'Thailand',
  'Timor-Leste',
  'Togo',
  'Tokelau',
  'Tonga',
  'Trinidad & Tobago',
  'Tristan da Cunha',
  'Tunisia',
  'Turkey',
  'Turkmenistan',
  'Turks & Caicos Islands',
  'Tuvalu',
  'U.S. Outlying Islands',
  'U.S. Virgin Islands',
  'Uganda',
  'Ukraine',
  'United Arab Emirates',
  'United Kingdom',
  'Uruguay',
  'Uzbekistan',
  'Vanuatu',
  'Vatican City',
  'Venezuela',
  'Vietnam',
  'Wallis & Futuna',
  'Western Sahara',
  'Yemen',
  'Zambia',
  'Zimbabwe',
]

export const interests = [
  'Adult Rental',
  'Adult League',
  'Adult Tournament',
  'Pick Up',
  'Youth Rental',
  'Youth League',
  'Youth Tournament',
  'Birthday Party',
  'Corporate Event',
]
